import React, { useContext, useReducer } from 'react';
import axios from 'axios';
import UserContext from '../../context/UserContext';
import { Form, Select, SubmitButton, TextInput } from '../Form';
import reducer, { initialState, FormActions, FormStates } from './reducer';
import useHostname from '../../hooks/useHostname';
import './styles.scss';

const { SUBMITTED, SUCCESS, SUBMIT_FAIL } = FormStates;
const {
  UPDATE_FORM_STATE,
  VALUE_CHANGE,
  RELAY_SERVER_VALIDATION_ERRORS,
  UPDATE_CLASSROOMS,
} = FormActions;

const ClassroomForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userContext = useContext(UserContext);
  const hostname = useHostname();

  const submitForm = (event) => {
    event.preventDefault();
    dispatch({ type: UPDATE_FORM_STATE, payload: SUBMITTED });
    if (
      Object.keys(state.fields).every((field) => state.fields[field].isValid)
    ) {
      axios
        .post('/api/classroom-lookup', {
          'grade-band': state.fields.gradeBand.value,
          zip: state.fields.zip.value,
        })
        .then((response) => {
          if (response.data && response.data.schools) {
            if (Array.isArray(response.data.schools)) {
              if (response.data.schools.length) {
                dispatch({
                  type: UPDATE_CLASSROOMS,
                  payload: response.data.schools,
                });
                dispatch({ type: UPDATE_FORM_STATE, payload: SUCCESS });
              } else {
                dispatch({
                  type: RELAY_SERVER_VALIDATION_ERRORS,
                  payload: `No schools were found for zipcode ${state.fields.zip.value}`,
                });
              }
            } else {
              // 'schools' is not an array :(
              dispatch({ type: UPDATE_FORM_STATE, payload: SUBMIT_FAIL });
            }
          } else {
            // No response data :(
            dispatch({ type: UPDATE_FORM_STATE, payload: SUBMIT_FAIL });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.message) {
              dispatch({
                type: RELAY_SERVER_VALIDATION_ERRORS,
                payload: err.response.data.message,
              });
            } else {
              // A 400 *without* a message is unexpected, show a default message.
              dispatch({ type: UPDATE_FORM_STATE, payload: SUBMIT_FAIL });
            }
          } else {
            // Non-400 responses are considered a failed submit
            dispatch({ type: UPDATE_FORM_STATE, payload: SUBMIT_FAIL });
          }
        });
    }
  };

  const handleFieldChangeTrimValue = (name, value) => {
    const modValue = value && typeof value === 'string' ? value.trim() : value;
    handleFieldChange(name, modValue);
  };

  const handleFieldChange = (name, value) => {
    dispatch({
      type: VALUE_CHANGE,
      payload: { name, value },
    });
  };

  const getServerErrors = () => {
    if (state.serverSideValidationErrors.length) {
      return (
        <div className="register-form-server-errors">
          {state.serverSideValidationErrors.length
            ? state.serverSideValidationErrors.map((msg) => (
                <p
                  key={msg}
                  style={{ color: 'red' }}
                  dangerouslySetInnerHTML={{ __html: msg }}
                />
              ))
            : null}
        </div>
      );
    }
  };

  const renderPleaseLogIn = () => {
    return <h4>You must Log in to query classrooms.</h4>;
  };

  const getMailTo = (email) => {
    const letterUrl = `${hostname}/letter`;
    const emailBody = `Hello!
    I’m a local STEM Professional reaching out to let you know that I would love to help facilitate an activity or lead a career-related discussion with your students as part of the Discover Data initiative.  
            
    This community initiative, created by Discovery Education, the Nielsen Foundation and the Afterschool Alliance at no cost to educators or schools, aims to bring the world of data science and data-related careers to life for America’s students through free online activities and Career profiles, as well as in-person volunteer classroom visits from real-world data professionals like myself.
            
    An Introduction letter can be downloaded from ${letterUrl}. It explains the program, resources and opportunity for volunteers (like me!) to visit a classroom or afterschool enrichment program to help students understand what a 21st Century career in data looks like.  Once you have taken a look, please let me know if this is something you might be interested in. You and your teachers can also register for a volunteer visit and Learn more about Discovery Education, the Nielsen Foundation and The Afterschool Alliance directly at http://www.discoverdatainschool.org.
    `;

    return `mailto:${email.toLowerCase()}?subject=Seeking%20an%20Opportunity%20to%20Volunteer&body=${encodeURI(
      emailBody
    )}`;
  };

  const renderClassrooms = () => {
    return (
      <>
        <h2>Find a classroom</h2>
        {state.classrooms.map((classroom) => (
          <div key={classroom.PRINCIPAL_EMAIL} className="mb-2">
            <p>
              <strong>{classroom.INST_FULL}</strong>
              <br />
              {classroom.MSTREET}
              <br />
              {classroom.URL}
              <br />
              {`${classroom.AREACODE}-${classroom.EXCHANGE}-${classroom.NUMBER}`}
              <br />
              {`Principal ${classroom.PRINCIPAL_NAME}`}
              <br />
              {classroom.PRINCIPAL_EMAIL && (
                <a href={`mailto:${classroom.PRINCIPAL_EMAIL.toLowerCase()}`}>
                  {classroom.PRINCIPAL_EMAIL.toLowerCase()}
                </a>
              )}
              <br />
              {classroom.PRINCIPAL_EMAIL && (
                <strong>
                  <a href={getMailTo(classroom.PRINCIPAL_EMAIL)}>
                    Send An Email
                  </a>
                </strong>
              )}
            </p>
          </div>
        ))}
      </>
    );
  };

  const renderForm = () => {
    return (
      <div className="classroom-form">
        <Form>
          <h2>Find a classroom</h2>
          <h4>US Classrooms only</h4>
          <Select
            label="Grade Band"
            name="gradeBand"
            placeholder="- Select -"
            options={{
              all: 'All Schools and Programs',
              'middle-school': 'Middle School',
              'high-school': 'High School',
              afterschool: 'Afterschool',
            }}
            error={
              state.showValidationErrors && state.fields.gradeBand.errorMessage
            }
            onChange={handleFieldChange}
          />
          <TextInput
            type="text"
            label="ZIP Code"
            name="zip"
            value={state.fields.zip.value}
            error={state.showValidationErrors && state.fields.zip.errorMessage}
            onChange={handleFieldChangeTrimValue}
          />
          <SubmitButton onClick={submitForm}>Search</SubmitButton>
          {getServerErrors()}
        </Form>
      </div>
    );
  };

  return (
    <div className="find-a-classroom">
      {!userContext.loggedIn
        ? renderPleaseLogIn()
        : state.classrooms.length
        ? renderClassrooms()
        : renderForm()}
    </div>
  );
};

export default ClassroomForm;
