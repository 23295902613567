import FormUtils from '../Form/FormUtils';
import FormExtra from '../../utils/FormExtra';

/**
 * States of the form. Used by the reducer for control flow.
 */
const RegisterFormStates = {
  LOADING: 'LOADING',
  READY: 'READY',
  LOAD_FAILED: 'LOAD_FAILED',
  SUBMITTED: 'SUBMITTED',
  SUCCESS: 'SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
};

/**
 * Actions supported by this reducer.
 */
const RegisterFormActions = {
  SET_OPTIONS: 'SET_OPTIONS',
  UPDATE_FORM_STATE: 'UPDATE_FORM_STATE',
  RELAY_SERVER_VALIDATION_ERRORS: 'RELAY_SERVER_VALIDATION_ERRORS',
  VALUE_CHANGE: 'VALUE_CHANGE',
};

const initialState = {
  formState: RegisterFormStates.LOADING,
  options: {
    countries: {},
    states: {},
    reasons: {},
  },
  showValidationErrors: false,
  serverSideValidationErrors: [],
  fields: {
    'field_first_name[0][value]': {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
    'field_last_name[0][value]': {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
    mail: {
      value: '',
      isValid: false,
      validator: [
        FormUtils.validators.email,
        FormExtra.validators.nielsenEmail,
      ],
      errorMessage: '',
    },
    pass: {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
    field_country: {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
    'field_city[0][value]': {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
    field_state: {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
    field_reason_registering: {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
  },
};

const reducer = (state, action) => {
  let newState;
  switch (action.type) {
    case RegisterFormActions.VALUE_CHANGE:
      // Show error in console when updating value of undefined field.
      if (state.fields[action.payload.name] === undefined) {
        console.error(`Undefined field ${action.payload.name}`);
        return state;
      }
      // Modify state
      newState = {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: {
            ...state.fields[action.payload.name],
            value: action.payload.value,
          },
        },
      };
      // Validate the new value
      let currentValidators = state.fields[action.payload.name].validator;
      const errMessage = FormUtils.validate(
        action.payload.value,
        currentValidators
      );
      newState.fields[action.payload.name].isValid = !errMessage;
      newState.fields[action.payload.name].errorMessage = errMessage;
      return newState;
    // Update <select> options provided by the backend via an AJAX call.
    case RegisterFormActions.SET_OPTIONS:
      if (state.options[action.payload.name] === undefined) {
        console.error(`Options key not recognized: ${action.payload.name}`);
        return state;
      }
      newState = {
        ...state,
        options: {
          ...state.options,
          [action.payload.name]: {
            ...action.payload.value,
          },
        },
      };
      return newState;
    // React to form state changes
    case RegisterFormActions.UPDATE_FORM_STATE:
      if (
        action.payload === RegisterFormStates.LOAD_FAILED ||
        action.payload === RegisterFormStates.SUBMIT_FAIL
      ) {
        // Whoops we shouldn't get an error during load,
        // but in any event let's send the user to the Drupal register page
        window.location.assign('/user/register');
      }
      newState = {
        ...state,
        formState: action.payload,
      };
      // If form was submitted once, enable error display
      if (action.payload === RegisterFormStates.SUBMITTED) {
        newState.showValidationErrors = true;
        // Validate Fields as well, as initially no fields have been validated
        // and at this point, we should run validation to flag empty and untouched required fields.
        newState = FormUtils.validateFields(newState);
      }
      return newState;
    case RegisterFormActions.RELAY_SERVER_VALIDATION_ERRORS:
      newState = { ...state };
      if (action.payload != null && typeof action.payload === 'object') {
        const keys = Object.keys(action.payload);
        if (keys.length) {
          newState = {
            ...state,
          };
          const serverErrors = [];
          keys.forEach((key) => {
            if (state.fields.hasOwnProperty(key)) {
              serverErrors.push(action.payload[key]);
            } else {
              // We should not have unexpected keys here, so log it:
              console.error(`Unrecognized field name: ${key}`);
            }
          });
          newState.serverSideValidationErrors = serverErrors;
        }
      }
      return newState;
    default:
      console.error('Unexpected action');
      return state;
  }
};

export default reducer;

export { initialState, RegisterFormActions, RegisterFormStates };
