import React, { useEffect, useReducer, useContext } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Form, Select, SubmitButton, TextInput } from '../Form';
import UserContext from '../../context/UserContext';
import reducer, {
  initialState,
  RegisterFormActions,
  RegisterFormStates,
} from './reducer';
import './styles.scss';
const {
  LOADING,
  READY,
  LOAD_FAILED,
  SUBMITTED,
  SUCCESS,
  SUBMIT_FAIL,
} = RegisterFormStates;
const {
  SET_OPTIONS,
  UPDATE_FORM_STATE,
  VALUE_CHANGE,
  RELAY_SERVER_VALIDATION_ERRORS,
} = RegisterFormActions;

const RegisterForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userContext = useContext(UserContext);

  const convertOptionsArrayToMap = (options) => {
    return options.reduce((accumulator, currentValue) => {
      // Options returned from API are an array where each element of the array
      // is one option of the form { key: value }
      // So we convert this array of objects into one object.
      const currentKey = Object.keys(currentValue)[0];
      accumulator[currentKey] = currentValue[currentKey];
      return accumulator;
    }, {});
  };

  //Component load handler that retrieves options for <Select> elements.
  useEffect(() => {
    // Logged in users will not see the registration form.
    if (userContext.loggedIn) return;

    axios
      .get('/api/register-user')
      .then((response) => {
        if (
          response.data.options &&
          response.data.options.countries &&
          response.data.options.states &&
          response.data.options.reasons
        ) {
          dispatch({
            type: SET_OPTIONS,
            payload: {
              name: 'countries',
              value: convertOptionsArrayToMap(response.data.options.countries),
            },
          });
          dispatch({
            type: SET_OPTIONS,
            payload: {
              name: 'states',
              value: convertOptionsArrayToMap(response.data.options.states),
            },
          });
          dispatch({
            type: SET_OPTIONS,
            payload: {
              name: 'reasons',
              value: convertOptionsArrayToMap(response.data.options.reasons),
            },
          });
          dispatch({ type: UPDATE_FORM_STATE, payload: READY });
        } else {
          // Some required form data is missing, show error
          console.error('Missing Options');
          dispatch({ type: UPDATE_FORM_STATE, payload: LOAD_FAILED });
        }
      })
      .catch((err) => {
        // Errors here shouldn't happen here, let's log it
        console.error(err);
        dispatch({ type: UPDATE_FORM_STATE, payload: LOAD_FAILED });
      });
  }, [userContext.loggedIn]);

  const handleFieldChange = (name, value) => {
    dispatch({
      type: VALUE_CHANGE,
      payload: { name, value },
    });
  };

  const submitForm = (event) => {
    event.preventDefault();
    dispatch({ type: UPDATE_FORM_STATE, payload: SUBMITTED });
    if (
      Object.keys(state.fields).every((field) => state.fields[field].isValid)
    ) {
      axios
        .post(
          '/api/register-user',
          queryString.stringify({
            form_id: 'user_register_form',
            op: 'Register',
            'field_first_name[0][value]':
              state.fields['field_first_name[0][value]'].value,
            'field_last_name[0][value]':
              state.fields['field_last_name[0][value]'].value,
            mail: state.fields.mail.value,
            pass: state.fields.pass.value,
            field_country: state.fields.field_country.value,
            'field_city[0][value]': state.fields['field_city[0][value]'].value,
            field_state: state.fields.field_state.value,
            field_reason_registering:
              state.fields.field_reason_registering.value,
          })
        )
        .then(() => {
          dispatch({ type: UPDATE_FORM_STATE, payload: SUCCESS });
          userContext.checkAgain();
        })
        .catch((err) => {
          if (err.response.data.errors) {
            // The backend found validation errors.
            // TODO get error array
            dispatch({
              type: RELAY_SERVER_VALIDATION_ERRORS,
              payload: err.response.data.errors,
            });
          } else {
            // If we are here, there was probably some
            // connection or controller error.
            dispatch({ type: UPDATE_FORM_STATE, payload: SUBMIT_FAIL });
          }
        });
    }
  };

  const getServerErrors = () => {
    if (state.serverSideValidationErrors.length) {
      return (
        <div className="register-form-server-errors">
          {state.serverSideValidationErrors.length
            ? state.serverSideValidationErrors.map((msg) => (
                <p
                  style={{ color: 'red' }}
                  dangerouslySetInnerHTML={{ __html: msg }}
                />
              ))
            : null}
        </div>
      );
    }
  };

  const renderForm = () => {
    return (
      <Form>
        <h2>Create an account</h2>
        <p>
          Thank you for your interest in volunteering! This registration
          provides access to specific resources available exclusively to Nielsen
          associates. In order to access these resources, please proceed with
          your @nielsen email address to complete the registration process.
        </p>
        <p>
          If you are an employee who does not have an @nielsen email address,
          please contact{' '}
          <a href="mailto:nielsen.foundation@nielsen.com">
            nielsen.foundation@nielsen.com
          </a>{' '}
          to be setup with login credentials.
        </p>
        <h4>Your Account</h4>
        <TextInput
          label="First Name"
          type="text"
          name="field_first_name[0][value]"
          value={state.fields['field_first_name[0][value]'].value}
          error={
            state.showValidationErrors &&
            state.fields['field_first_name[0][value]'].errorMessage
          }
          onChange={handleFieldChange}
        />
        <TextInput
          type="text"
          label="Last Name"
          name="field_last_name[0][value]"
          value={state.fields['field_last_name[0][value]'].value}
          error={
            state.showValidationErrors &&
            state.fields['field_last_name[0][value]'].errorMessage
          }
          onChange={handleFieldChange}
        />
        <TextInput
          type="email"
          label="Email"
          name="mail"
          value={state.fields.mail.value}
          error={state.showValidationErrors && state.fields.mail.errorMessage}
          onChange={handleFieldChange}
        />
        <TextInput
          type="password"
          label="Password"
          name="pass"
          value={state.fields.pass.value}
          error={state.showValidationErrors && state.fields.pass.errorMessage}
          onChange={handleFieldChange}
        />

        <h4>Your location</h4>
        <Select
          label="Country"
          name="field_country"
          options={state.options.countries}
          error={
            state.showValidationErrors &&
            state.fields.field_country.errorMessage
          }
          onChange={handleFieldChange}
        />
        <TextInput
          type="text"
          label="City"
          name="field_city[0][value]"
          value={state.fields['field_city[0][value]'].value}
          error={
            state.showValidationErrors &&
            state.fields['field_city[0][value]'].errorMessage
          }
          onChange={handleFieldChange}
        />
        <Select
          label="State"
          name="field_state"
          options={state.options.states}
          error={
            state.showValidationErrors && state.fields.field_state.errorMessage
          }
          onChange={handleFieldChange}
        />

        <h4>Tell us about yourself!</h4>
        <Select
          label="Which best describes your reason for registering today?"
          name="field_reason_registering"
          options={state.options.reasons}
          error={
            state.showValidationErrors &&
            state.fields.field_reason_registering.errorMessage
          }
          onChange={handleFieldChange}
        />
        <SubmitButton onClick={submitForm}>Submit</SubmitButton>
        {getServerErrors()}
      </Form>
    );
  };

  const renderSuccess = () => {
    return <h4>You were successfully registered!</h4>;
  };

  const renderSubmitFail = () => {
    return (
      <h4>There was an unexpected error while processing registration.</h4>
    );
  };

  const renderAlreadyLoggedIn = () => {
    return <h4>You are already logged in.</h4>;
  };

  return (
    <div className="register-form">
      {state.formState === SUBMIT_FAIL
        ? renderSubmitFail()
        : state.formState === SUCCESS
        ? renderSuccess()
        : userContext.loggedIn
        ? renderAlreadyLoggedIn()
        : state.formState === LOADING
        ? null
        : renderForm()}
    </div>
  );
};

export default RegisterForm;

export { RegisterFormStates };
