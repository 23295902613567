import React, { useContext } from 'react';
import UserContext from '../../context/UserContext';
import Modal from '../Modal';
import Button from '../Button';
import Icon from '../Icon';
import LoginForm from '../LoginForm';
import ClassroomForm from '../ClassroomForm';
import RegisterForm from '../RegisterForm';

/**
 * The main component for this file.
 */
const ClassroomOrLoginButtons = () => {
  const userContext = useContext(UserContext);

  const renderButtons = () => {
    return (
      <div className="classroom-or-login">
        {!userContext.loggedIn && (
          <>
            <p>
              <Modal
                trigger={
                  <Button>
                    <Icon name="lock" />
                    <span className="ml-1">Log in</span>
                  </Button>
                }
              >
                <LoginForm />
              </Modal>
            </p>
            <p>
              <Modal
                trigger={
                  <Button isSecondary={true}>
                    <Icon name="lock" />
                    <span className="ml-1">Create an account</span>
                  </Button>
                }
              >
                <RegisterForm />
              </Modal>
            </p>
          </>
        )}
        {userContext.loggedIn && (
          <Modal
            trigger={
              <Button>
                <Icon name="lock" />
                <span className="ml-1">Find a classroom</span>
              </Button>
            }
          >
            <ClassroomForm />
          </Modal>
        )}
      </div>
    );
  };

  return <>{userContext.finishedInitialCheck && renderButtons()}</>;
};

export default ClassroomOrLoginButtons;
