import React, { useContext } from 'react';
import UserContext from '../../context/UserContext';
import Modal from '../Modal';
import Button from '../Button';
import Icon from '../Icon';
import LoginForm from '../LoginForm';
import RegisterForm from '../RegisterForm';

/**
 * The main component for this file.
 */
const LoginLogoutButtons = () => {
  const userContext = useContext(UserContext);

  return (
    <div style={{ display: 'flex' }} className="login-logout">
      {!userContext.loggedIn && (
        <>
          <Modal
            trigger={
              <Button className="ml-1">
                <Icon name="lock" />
                <span className="ml-1">Login</span>
              </Button>
            }
          >
            <LoginForm />
          </Modal>
          <Modal
            trigger={
              <Button isSecondary={true} className="ml-1">
                <Icon name="lock" />
                <span className="ml-1">Create an account</span>
              </Button>
            }
          >
            <RegisterForm />
          </Modal>
        </>
      )}
      {userContext.loggedIn && (
        <Button to="/user/logout" forceExternal>
          Logout
        </Button>
      )}
    </div>
  );
};

export default LoginLogoutButtons;
