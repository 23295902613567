/**
 * Shared validators for form fields
 */
const validators = {
  nielsenEmail: {
    checkValue: (value) => /^[^\s@]+@(nielsen|discoveryed).com$/.test(value),
    errorMessage: 'A Nielsen company email is required to register.',
  },
};

const extra = { validators };

export default extra;
