/**
 * Shared validators for form fields
 */
const validators = {
  email: {
    checkValue: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
    errorMessage: 'Email must be a valid format',
  },
  zip: {
    checkValue: (value) => /^[0-9]{5}(-[0-9]{4})?$/.test(value),
    errorMessage: 'Zipcode must be a valid format',
  },
  nonEmptyString: {
    checkValue: (value) => !!value.trim(),
    errorMessage: 'Field cannot be empty',
  },
};

/**
 * Test a value against a set of validators. See above 'validators' object
 * for the expected format of a validator.
 *
 * @param {any} fieldValue  The value being checked
 * @param {array|object} fieldValidators  A single validator or array of validators
 *
 * @returns {string|null} Null is returned if the value passed all validation tests
 */
const validate = (fieldValue, fieldValidators) => {
  let errMessage = null;
  // Make the validators an array
  // i.e. each field can have a single validator entry, or an array,
  // here we normalize either case to an array
  let currentValidators = fieldValidators;
  if (!Array.isArray(currentValidators)) {
    currentValidators = [currentValidators];
  }
  // Now run  test, and on the first fail, record the error message
  for (let i = 0; i < currentValidators.length; i++) {
    if (!currentValidators[i].checkValue(fieldValue)) {
      errMessage = currentValidators[i].errorMessage;
      break;
    }
  }
  return errMessage;
};

/**
 * Helper to update state by validating fields and adding
 * error messages if needed.
 */
const validateFields = (state) => {
  const newState = {
    ...state,
  };
  Object.keys(state.fields).forEach((field) => {
    let currentValidators = state.fields[field].validator;
    const errMessage = validate(state.fields[field].value, currentValidators);
    newState.fields[field].isValid = !errMessage;
    newState.fields[field].errorMessage = errMessage;
  });
  return newState;
};

const FormObject = {
  validate,
  validateFields,
  validators,
};

export default FormObject;
