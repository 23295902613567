import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../context/UserContext';
import Modal from '../Modal';
import Button from '../Button';
import Icon from '../Icon';
import LoginForm from '../LoginForm';
import RegisterForm from '../RegisterForm';

const LoginOrOther = ({ lockedContent }) => {
  const userContext = useContext(UserContext);

  return (
    <div className="login-or-other">
      {!userContext.loggedIn && (
        <>
          <p>
            <Modal
              trigger={
                <Button>
                  <Icon name="lock" />
                  <span className="ml-1">Login</span>
                </Button>
              }
            >
              <LoginForm />
            </Modal>
          </p>
          <p>
            <Modal
              trigger={
                <Button isSecondary={true}>
                  <Icon name="lock" />
                  <span className="ml-1">Create an account</span>
                </Button>
              }
            >
              <RegisterForm />
            </Modal>
          </p>
        </>
      )}
      {userContext.loggedIn && lockedContent}
    </div>
  );
};

LoginOrOther.defaultProps = {
  lockedContent: null,
};

LoginOrOther.propTypes = {
  /** Custom content to show after logging in with the login buttons */
  lockedContent: PropTypes.any,
};

export default LoginOrOther;
