import FormUtils from '../Form/FormUtils';

/**
 * States of the form. Used by the reducer for control flow.
 */
const FormStates = {
  LOADING: 'LOADING',
  READY: 'READY',
  LOAD_FAILED: 'LOAD_FAILED',
  SUBMITTED: 'SUBMITTED',
  SUCCESS: 'SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
};

/**
 * Actions supported by this reducer.
 */
const FormActions = {
  SET_OPTIONS: 'SET_OPTIONS',
  UPDATE_FORM_STATE: 'UPDATE_FORM_STATE',
  RELAY_SERVER_VALIDATION_ERRORS: 'RELAY_SERVER_VALIDATION_ERRORS',
  VALUE_CHANGE: 'VALUE_CHANGE',
};

const initialState = {
  formState: FormStates.LOADING,
  showValidationErrors: false,
  serverSideValidationErrors: [],
  fields: {
    username: {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
    pass: {
      value: '',
      isValid: false,
      validator: FormUtils.validators.nonEmptyString,
      errorMessage: '',
    },
  },
};

const DEFAULT_ERR_MESSAGE = 'Login connection failed. Please try again later.';

const reducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    case FormActions.VALUE_CHANGE:
      // Show error in console when updating value of undefined field.
      if (state.fields[action.payload.name] === undefined) {
        console.error(`Undefined field ${action.payload.name}`);
        return state;
      }
      // Modify state
      newState = {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: {
            ...state.fields[action.payload.name],
            value: action.payload.value,
          },
        },
      };
      let currentValidators = state.fields[action.payload.name].validator;
      const errMessage = FormUtils.validate(
        action.payload.value,
        currentValidators
      );
      newState.fields[action.payload.name].isValid = !errMessage;
      newState.fields[action.payload.name].errorMessage = errMessage;
      return newState;
    // React to form state changes
    case FormActions.UPDATE_FORM_STATE:
      newState = {
        ...state,
        formState: action.payload,
      };
      // If form was submitted once, enable error display
      if (action.payload === FormStates.SUBMITTED) {
        newState.showValidationErrors = true;
        // Validate Fields as well, as initially no fields have been validated
        // and at this point, we should run validation to flag empty and untouched required fields.
        newState = FormUtils.validateFields(newState);
      }
      // Handle case when API calls fails
      if (action.payload === FormStates.SUBMIT_FAIL) {
        newState.serverSideValidationErrors = [DEFAULT_ERR_MESSAGE];
      }
      return newState;
    case FormActions.RELAY_SERVER_VALIDATION_ERRORS:
      if (action.payload != null && typeof action.payload === 'object') {
        const keys = Object.keys(action.payload);
        if (keys.length) {
          newState = {
            ...state,
          };
          const serverErrors = [];
          keys.forEach((key) => {
            if (state.fields.hasOwnProperty(key)) {
              serverErrors.push(action.payload[key]);
            } else {
              // We should not have unexpected keys here, so log it:
              console.error(`Unrecognized field name: ${key}`);
            }
          });
          newState.serverSideValidationErrors = serverErrors;
        }
      } else if (action.payload != null && typeof action.payload === 'string') {
        newState.serverSideValidationErrors = [action.payload];
      } else {
        newState.serverSideValidationErrors = [DEFAULT_ERR_MESSAGE];
      }
      return newState;
    default:
      console.error('Unexpected action');
      return state;
  }
};

export default reducer;

export { initialState, FormActions, FormStates };
