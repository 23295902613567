import React, { useContext, useReducer } from 'react';
import axios from 'axios';
import UserContext from '../../context/UserContext';
import { Form, SubmitButton, TextInput } from '../Form';
import reducer, { initialState, FormActions, FormStates } from './reducer';
import './styles.scss';
import Button from '../Button';

const { SUBMITTED, SUCCESS, SUBMIT_FAIL } = FormStates;
const {
  UPDATE_FORM_STATE,
  VALUE_CHANGE,
  RELAY_SERVER_VALIDATION_ERRORS,
} = FormActions;

const LoginForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userContext = useContext(UserContext);

  const submitForm = (event) => {
    event.preventDefault();
    dispatch({ type: UPDATE_FORM_STATE, payload: SUBMITTED });
    if (
      Object.keys(state.fields).every((field) => state.fields[field].isValid)
    ) {
      axios
        .post('/user/login?_format=json', {
          name: state.fields.username.value,
          pass: state.fields.pass.value,
        })
        .then(() => {
          dispatch({ type: UPDATE_FORM_STATE, payload: SUCCESS });
          window.location.reload();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.message) {
              dispatch({
                type: RELAY_SERVER_VALIDATION_ERRORS,
                payload: err.response.data.message,
              });
            } else {
              // A 400 *without* a message is unexpected, show a default message.
              dispatch({ type: UPDATE_FORM_STATE, payload: SUBMIT_FAIL });
            }
          } else {
            // Non-400 responses are considered a failed submit
            dispatch({ type: UPDATE_FORM_STATE, payload: SUBMIT_FAIL });
          }
        });
    }
  };

  const handleFieldChange = (name, value) => {
    dispatch({
      type: VALUE_CHANGE,
      payload: { name, value },
    });
  };

  const getServerErrors = () => {
    if (state.serverSideValidationErrors.length) {
      return (
        <div className="register-form-server-errors">
          {state.serverSideValidationErrors.length
            ? state.serverSideValidationErrors.map((msg) => (
                <p
                  key={msg}
                  style={{ color: 'red' }}
                  dangerouslySetInnerHTML={{ __html: msg }}
                />
              ))
            : null}
        </div>
      );
    }
  };

  const renderAlreadyLoggedIn = () => {
    return <h4>You are already logged in.</h4>;
  };

  const renderForm = () => {
    return (
      <div className="login-form">
        <Form>
          <h2>Log in</h2>
          <TextInput
            type="text"
            label="Email"
            name="username"
            value={state.fields.username.value}
            error={
              state.showValidationErrors && state.fields.username.errorMessage
            }
            onChange={handleFieldChange}
          />
          <TextInput
            type="password"
            label="Password"
            name="pass"
            value={state.fields.pass.value}
            error={state.showValidationErrors && state.fields.pass.errorMessage}
            onChange={handleFieldChange}
          />
          <SubmitButton onClick={submitForm}>Log in</SubmitButton>
          <Button link forceExternal to="/user/password">
            Forgot Password?
          </Button>
          {getServerErrors()}
        </Form>
      </div>
    );
  };

  return <>{userContext.loggedIn ? renderAlreadyLoggedIn() : renderForm()}</>;
};

export default LoginForm;
