import React from 'react';
import Layout from '../components/Layout';
import Callout from '../components/Callout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Resource from '../components/Resource';
import Subfooter from '../components/Subfooter';
import Quicklinks from '../components/Quicklinks';
import SurveyCallout from '../components/SurveyCallout';
import Image from '../components/Image';
import Button from '../components/Button';
import Icon from '../components/Icon';
import { graphql } from 'gatsby';
import { getKeyedResources } from '../utils/resource-helper';
import LoginLogoutButtons from '../components/LoginLogoutButtons';
import LoginOrOther from '../components/LoginOrOther';
import ClassroomOrLoginButtons from '../components/ClassroomOrLoginButtons';
import Modal from '../components/Modal';
import Video from '../components/Video';
import ScheduleAVisitForm from '../components/ScheduleAVisitForm';

const textOverlay = (
  <div className="volunteers__text_overlay_container">
    <Container fullWidth>
      <div className="volunteers__text_overlay_info">Volunteers</div>
    </Container>
  </div>
);

const subfooterContent = (
  <span>
    Become a data advocate today to inspire the STEM workforce of tomorrow.
  </span>
);

const Volunteers = ({ data }) => {
  const {
    volunteerGuide,
    volunteerWebinar,
    introductionLetter,
  } = getKeyedResources(data);

  return (
    <Layout title="Volunteers">
      <Hero
        mediaSource="img-lead-triangle-volunteers.png"
        textOverlay={textOverlay}
      />
      <Section className="volunteers__callout-section section--gray">
        <Container fullWidth>
          <Row className="volunteers__callout-row">
            <Column largeOffset={1} large={10}>
              <Callout
                className="volunteers__callout"
                containerClassName="volunteers__callout-container"
              >
                Inspire tomorrow’s leaders with the power of data
              </Callout>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 section--gray">
        <Container>
          <Row>
            <Column size={12}>
              <h2>Discover Data volunteers</h2>
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Quicklinks
                className="volunteers__quicklinks"
                links={[
                  {
                    target: '#welcome',
                    label: 'Welcome',
                  },
                  {
                    target: '#resources',
                    label: 'Resources',
                  },
                  {
                    target: '#find-a-classroom',
                    label: 'Find a classroom',
                  },
                  {
                    target: '#connect-expert',
                    label: 'Connect with a data expert',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section id="welcome" className={'section--gray'}>
        <Container>
          <Row>
            <Column size={5}>
              <Image
                filename="volunteer-intro@2x.jpg"
                className="volunteers__images"
              />
            </Column>
            <Column size={7} className="text-sans-serif">
              <p>
                Discover Data volunteers have the unique power to inspire
                awareness and interest around 21st century careers that use data
                to make the world a better place. The volunteer component of
                this program provides students with a first-hand connection to
                what’s possible as they understand and apply steps in the data
                science process to problems that interest them. Discover Data’s
                Volunteer resources are designed to equip our community of data
                professionals with everything they need to make their classroom
                visit a success and inspire tomorrow’s leaders with the power of
                data.
              </p>
              <h2>Nielsen volunteer login</h2>
              <p>
                Login now or signup to become a member of the Discover Data
                community and receive updates on new resources and exciting
                learning opportunities.
              </p>
              <LoginLogoutButtons />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section id="resources">
        <Container>
          <Row>
            <Column size={12}>
              <h2>Volunteer resources</h2>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={volunteerGuide.tophat}
                img={volunteerGuide.img}
                title={volunteerGuide.title}
                description={volunteerGuide.description}
                className={volunteerGuide.className}
                actions={volunteerGuide.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={introductionLetter.tophat}
                img={introductionLetter.img}
                title={introductionLetter.title}
                description={introductionLetter.description}
                className={introductionLetter.className}
                actions={introductionLetter.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={volunteerWebinar.tophat}
                img={volunteerWebinar.img}
                title={volunteerWebinar.title}
                description={volunteerWebinar.description}
                className={volunteerWebinar.className}
                customAction={
                  <LoginOrOther
                    lockedContent={
                      <Modal
                        trigger={
                          <Button>
                            <Icon name="externallink" />
                            <span className="ml-1">Watch webinar</span>
                          </Button>
                        }
                      >
                        <Video
                          src="https://cep-de-primaryusprod-use2.streaming.media.azure.net/ad035a45-2c24-41ab-8991-788257c12357/NielsenTrainingWebinarFinalsourc.ism/manifest"
                          autoplay
                        />
                      </Modal>
                    }
                  />
                }
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pt-0">
        <Container className={'volunteers__images'}>
          <Row id="find-a-classroom" className="mb-4">
            <Column size={4}>
              <Image filename="promo-find-classroom@2x.jpg" />
            </Column>
            <Column size={8} className="text-sans-serif">
              <h2 className="mb-0">Find a classroom</h2>
              <p>
                Once you have reviewed the training resources on Discover Data,
                use the “Find a classroom” tool to connect local educators in
                your community.
              </p>
              <ClassroomOrLoginButtons />
            </Column>
          </Row>
          <Row id="connect-expert">
            <Column size={4}>
              <Image filename="promo-request-a-visit@2x.jpg" />
            </Column>
            <Column size={8} className="text-sans-serif">
              <h2 className="mb-0">Virtually connect with a data expert</h2>
              <p>
                Give students firsthand insight into the ways data is innovating
                the world around them by facilitating a dynamic data-driven
                learning session with a real-world data expert.
              </p>
              <Modal
                trigger={
                  <Button>
                    <Icon name="externallink" />
                    <span className="ml-1">Request a visit</span>
                  </Button>
                }
              >
                <ScheduleAVisitForm />
              </Modal>
            </Column>
          </Row>
        </Container>
      </Section>
      <SurveyCallout />
      <Section className="pt-0 pb-0">
        <Subfooter
          img="footer-volunteers@2x.jpg"
          content={subfooterContent}
          icon="icon-subject.svg"
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query VolunteerResources {
    allResourcesJson(filter: { pages: { in: "volunteers" } }) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            moduleName
            download
            noIcon
            asLink
          }
          horizontal
          className
          pages
        }
      }
    }
  }
`;

export default Volunteers;
