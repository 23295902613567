import { useEffect, useState } from 'react';

/**
 * AFTER the initial render, provide the hostname of the
 * current page.
 */
const useHostname = () => {
  const [hostname, setHostname] = useState('');

  // This will run once after the initial render.
  useEffect(() => {
    setHostname(
      window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    );
  }, []);

  return hostname;
};
export default useHostname;
